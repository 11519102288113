import {history} from "./common/router";
import React from "react";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import Introduce from "./home";
import NotFoundPage from "./404";
import About from "./home/about";

const AppRouter = () => {
    return (
      <Router history={history}>
          <Switch>
              <Route path = {"/"} exact component={Introduce}/>
              <Route path = {"/about"} component={About}/>
              <Route path = {"/404"} component={NotFoundPage}/>
              <Route path = "*">
                  <Redirect to = {`/404`}/>
              </Route>
          </Switch>
      </Router>
    );
}

export default AppRouter;