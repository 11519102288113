import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import { Tabs } from 'antd';
import customerBg from "./pic/customerbg.jpg";
import teamBg from "./pic/teambg.jpg";
import ideaDeclare from "./pic/idea-declare.png";
import {Layout} from "antd";
import {CopyrightOutlined} from "@ant-design/icons";
import _ from "lodash";

import Header from "./header";
import Swiper from "./swiper";
import MyFooter from "./footer";
import SideBar from "./side-bar";
const { Footer } = Layout;
const BasicAboutPanel = ({className}) => {
    const [tabKey, setTabKey] = useState("1");

    useEffect(() => {
        window.scrollTo(0,0);
        const search = window.location.search;
        if (search) {
            const keys = _.split(search, "=");
            setTabKey(keys[1]);
        }
    }, []);

    const handleTabClick = (key) => {
        setTabKey(key);
    }
    return (
        <div className={className}>
            <Header />
            <div className="container">
               <Swiper />
            </div>
            <div className="introduce">
                <Tabs activeKey={tabKey} className="introduce-tabs" centered size="large" onTabClick={handleTabClick}>
                    <Tabs.TabPane tab="公司简介" key="1">
                        <div>
                            <div className="introduce-tab1-content">
                                    <h1 style={{display: 'inline-block', marginRight: "0.5rem"}}>公司简介</h1>
                                    <span style={{fontStyle:'oblique'}}>
                                    始于既有，持于精进；客户为先，极致服务
                                </span>
                                    <h4>瑞吉传媒是一家从事创意策划及品牌传播的专业机构。公司集公关活动、展陈设计、影视制作、品牌推广、新媒体运营等业务形态于一体，持续致力于打造富有创造力和影响力的品牌公关和传播内容。</h4>
                            </div>
                            <div style={{backgroundImage: `url(${teamBg})`, backgroundSize: '100vw 100%'}}>
                                <div className="introduce-tab1-content">
                                    <h1>主创团队</h1>
                                    <h4>瑞吉主创团队拥有多年为各类机构、组织、企业服务的创意策划和品牌推广执行经验，对每一个项目都会挖掘需求背后的深层因素，提供最契合的策划方案。</h4>
                                    <h4>项目组一般由用户调研、创意文案、营销策划、视觉设计、影视制作、活动执行、客户服务经理组成。他们说：<em>“我们是一群对创意和美狂热追求的人。专注、高效、速度、责任感、对细节的极致追求，永不重复…是我们的关键词。”</em></h4>
                                </div>
                            </div>
                            <div className="introduce-tab1-content">
                                <h1>瑞吉愿景</h1>
                                <h4>成为客户首选的品牌传播服务合作伙伴</h4>
                                <h1>瑞吉使命</h1>
                                <h4>以最新技术和理念，服务各级组织，传播价值，成就不凡</h4>
                            </div>
                            <div style={{backgroundImage: `url(${customerBg})`, backgroundSize: '100vw 100%'}}>
                                <div className="introduce-tab1-content">
                                    <h1>客户共赢</h1>
                                    <h4>从本质上来讲，我们所提供的所有服务，都是为了帮助客户形成更加优质的商业模式，达成更加优良的商业结果。</h4>
                                    <h4>瑞吉与客户始终保持着密切的合作共赢关系。瑞吉在多元化的服务案例中，以专业的策划、出色的创意、高效的执行赢得客户充分认可和高度赞誉。用精彩创意和精致服务帮助客户赋予品牌丰富内涵，实现和提升品牌价值、与客户共同成就不凡，是瑞吉团队的持续追求。</h4>
                                </div>
                            </div>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="创意宣言" key="2">
                        <div style={{backgroundImage: `url(${ideaDeclare})`, backgroundSize: '100% 100%', border: '1px solid #fff'}}>
                            <div className="introduce-tab2-content">
                                <div></div>
                                <br/>
                                <h4>我们深知</h4>
                                <h4>设计不仅是一项展现美的技能</h4>
                                <h4>更是捕捉万事万物本质的</h4>
                                <h4>洞察力和感知力</h4>
                                <br/>
                                <h4>创新并不是为了标新立异，</h4>
                                <h4>一次次的不妥协，</h4>
                                <h4>是为了找到不同的维度，</h4>
                                <h4>追求光与影的极致呈现，</h4>
                                <h4>不断探索人与空间的可能。</h4>
                                <br/>
                                <h4>每一个Idea，</h4>
                                <h4>都是在有限元素中演变出的希望之光，</h4>
                                <h4>当灵感与Brief在争论的上空相遇，</h4>
                                <h4>创意便跃然纸上，</h4>
                                <h4>它是创意人的情感升华，</h4>
                                <h4>亦是品牌特定时空下的产物</h4>
                                <h4>是品牌和产品与用户之间相互理解的客观体现</h4>
                                <br/>
                                <h4>深度挖掘和理解客户内在真实需求是我们的追求</h4>
                                <h4>让我们的精彩创意和极致服务</h4>
                                <h4>助力每个客户构建品牌，实现价值，成就不凡！</h4>
                                <br/>
                                <div></div>
                            </div>
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <SideBar />
            <MyFooter />
            <Footer
                style={{
                    textAlign: "center",
                    color: "#aaa",
                    backgroundColor: "#303133",
                    fontSize: '1rem'
                }}
            >
                <CopyrightOutlined/>瑞吉传媒
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://beian.miit.gov.cn"
                >
                    沪ICP备 2023002738号-1
                </ a>
            </Footer>
        </div>
    );
}

const AboutPanel = styled(BasicAboutPanel)`
   .container {
    width: 100%;
  }
  
  .ant-tabs-tab {
    font-size: 25px !important;
    font-weight: bold;
  }
  
  .ant-tabs-nav {
    margin-bottom: 0 
  } 
   .introduce {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .introduce-tabs {
       width: 100%;
    }
    
    .introduce-tab1-content {
        width: 1350px;
        height: 220px;
        margin: 0 auto;
        padding-top: 10px;
        padding-left: 50px;
        
    }
    
    .introduce-tab2-content {
        text-align: center;
    }
   .introduce-tab2-content div {
     margin-top: 15px;
   }
   
   @media screen and (max-width:600px) {  
    .container {
    width: 100%;
  }
   .ant-tabs-tab {
    font-size: 20px !important;
    font-weight: bold;
  }
    .introduce-tabs {
        width: 100%;
    }
    .introduce-tab1-content {
        width: 95vw;
        height: 280px;
        padding-top: 20px;
        padding-left: 10px;
    }
      .introduce-tab2-content div {
        margin-top: 3px;
      }
  }
`;

export default AboutPanel;