import AppRouter from "./AppRouter";
import {Helmet, HelmetProvider} from "react-helmet-async";
import React from "react";

const App = () => {
    return (
        <HelmetProvider>
            <Helmet titleTemplate={`%s - 瑞吉传媒`} defaultTitle={"瑞吉传媒"} />
            <AppRouter />
        </HelmetProvider>
    );
};

export default App;
