import React, {useEffect} from "react";
import styled from "@emotion/styled";
import middle1 from "./pic/middle1.jpg";
import middle2 from "./pic/middle2.jpg";
import middle3 from "./pic/middle3.jpg";
import middle4 from "./pic/middle4.jpg";
import bottom1 from "./pic/bottom1.jpg";
import bottom2 from "./pic/bottom2.jpg";
import bottom3 from "./pic/bottom3.jpg";
import bottom4 from "./pic/bottom4.jpg";
import bottom5 from "./pic/bottom5.jpg";
import bottom6 from "./pic/bottom6.jpg";
import bottom7 from "./pic/bottom7.jpg";
import bottom8 from "./pic/bottom8.jpg";
import bottom9 from "./pic/bottom9.jpg";
import {Col, Layout, Row} from "antd";
import {CopyrightOutlined} from "@ant-design/icons";
import Header from "./header";
import Swiper from "./swiper";
import MyFooter from "./footer";
import SideBar from "./side-bar";
const {Footer} = Layout;

const BasicIntroducePanel = ({className}) => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className={className}>
            <Header />
            <div className="container">
                <Swiper />
                <div className="container-2">
                    <div className="title1">
                        <span>
                            以创意为核心，打造数字时代的全案品牌服务
                        </span>
                    </div>
                    <div className="container-2-content">
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <div className="container-2-content-div">
                                    <img src={middle1} alt="middle1" className="img-hover"/>
                                    <div className="container-2-content-div2">
                                        <p className="container-2-content-div2-bg">会议策划</p>
                                        <p className="container-2-content-div2-sm">搭建优质交流平台，实现对话的共享与碰撞</p>
                                    </div>
                                </div>
                                <div style={{textAlign: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                    <div>行业学术峰会、企业峰会论坛、城市推介会</div>
                                    <div>政府项目会议、赛事活动执行、海外商务会议</div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <div className="container-2-content-div">
                                    <img src={middle2} alt="middle2" className="img-hover"/>
                                    <div className="container-2-content-div2">
                                        <p className="container-2-content-div2-bg">展陈设计</p>
                                        <p className="container-2-content-div2-sm">独特美学视角，专业数据展陈技术，一站式集成服务</p>
                                    </div>
                                </div>
                                <div style={{textAlign: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                    <div>展台设计、展台搭建、主题特色展厅</div>
                                    <div>城市规划馆、科技展览、博览会、VR展</div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <div className="container-2-content-div">
                                    <img src={middle3} alt="middle3" className="img-hover"/>
                                    <div className="container-2-content-div2">
                                        <p className="container-2-content-div2-bg">影视制作</p>
                                        <p className="container-2-content-div2-sm">立足中外文化价值和内涵高度</p>
                                        <p className="container-2-content-div2-sm">通过镜头感悟世界，用影像讲述故事</p>
                                    </div>
                                </div>
                                <div style={{textAlign: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                    <div>产品宣传TVC、纪录片拍摄、城市宣传片</div>
                                    <div>企业数字宣传片、新媒体视频、会议活动主题视频</div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <div className="container-2-content-div">
                                    <img src={middle4} alt="middle4" className="img-hover"/>
                                    <div className="container-2-content-div2">
                                        <p className="container-2-content-div2-bg">品牌推广</p>
                                        <p className="container-2-content-div2-sm">通过多种形式和创意</p>
                                        <p className="container-2-content-div2-sm">将品牌信息精准传递给目标人群</p>
                                    </div>
                                </div>
                                <div style={{textAlign: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                    <div style={{fontFamily: "logo"}}>品牌创意广告、数字营销全案、VR视觉、IP文化、新媒体运营</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="container-3">
                    <div className="title2">
                        <strong>全域定制化服务</strong>
                    </div>
                    <div className="container-3-content">
                        <Row gutter={[16, 16]}>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <div className="container-3-content-div">
                                    <img src={bottom1} alt="bottom1" className="img-hover"/>
                                    <div className="container-3-content-desc">
                                        <h1>LOGO&VI</h1>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <div className="container-3-content-div">
                                    <img src={bottom2} alt="bottom2" className="img-hover"/>
                                    <div className="container-3-content-desc">
                                        <h1>元宇宙展</h1>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <div className="container-3-content-div">
                                    <img src={bottom3} alt="bottom3" className="img-hover"/>
                                    <div className="container-3-content-desc">
                                        <h1>影视动画</h1>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <div className="container-3-content-div">
                                    <img src={bottom4} alt="bottom4" className="img-hover"/>
                                    <div className="container-3-content-desc">
                                        <h1>主题展馆</h1>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <div className="container-3-content-div">
                                    <img src={bottom5} alt="bottom5" className="img-hover"/>
                                    <div className="container-3-content-desc">
                                        <h1>全案定制</h1>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <div className="container-3-content-div">
                                    <img src={bottom6} alt="bottom6" className="img-hover"/>
                                    <div className="container-3-content-desc">
                                        <h1>高峰论坛</h1>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <div className="container-3-content-div">
                                    <img src={bottom7} alt="bottom7" className="img-hover"/>
                                    <div className="container-3-content-desc">
                                        <h1>赛事运营</h1>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <div className="container-3-content-div">
                                    <img src={bottom8} alt="bottom8" className="img-hover"/>
                                    <div className="container-3-content-desc">
                                        <h1>数字营销</h1>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <div className="container-3-content-div">
                                    <img src={bottom9} alt="bottom9" className="img-hover"/>
                                    <div className="container-3-content-desc">
                                        <h1>行业博览</h1>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <MyFooter />
            </div>
            <SideBar />
            <Footer
                style={{
                    textAlign: "center",
                    color: "#aaa",
                    backgroundColor: "#303133",
                    fontSize: '1rem'
                }}
            >
                <CopyrightOutlined/>
                瑞吉传媒<a
                target="_blank"
                rel="noopener noreferrer"
                href="https://beian.miit.gov.cn"
            >
                沪ICP备 2023002738号-1
            </ a>
            </Footer>
        </div>
    );
}

const IntroducePanel = styled(BasicIntroducePanel)`
  .container {
    width: 100%;
  }

  .container-2 {
    width: 100%;
    background-color: rgb(250, 250 ,250)
  }
  
   .container-2-content {
    width: 1400px;
    margin: 0 auto;
  }
   
   .container-2-content-div {
    height: 450px;
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
   }
   
   .container-2-content-div2 {
    position: absolute;
    top: 130px;
    text-align: center;
   }
   
  .container-2-content-div2-bg {
    color: #fff;
    font-size: 35px
  }

  .container-2-content-div2-sm {
    color: #fff;
    font-size: 20px
  }
  
   .img-hover {
     cursor: pointer;
     width: 100%;
     height: 100%;
     transition: all 1s;
   }

   .img-hover:hover {
    transform: scale(1.1);
   }
    
  .container-3 {
    width: 100%;
    padding-bottom: 4rem;
    background-color: rgb(250, 250 ,250)
  }

  .container-3-content {
    width: 1400px;
    margin: 0 auto;
  }
  
  .container-3-content-div {
    height: 430px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  
  .container-3-content-desc {
    position: absolute;
    bottom: 40%;
    color: #fff
  }

  .title1 {
    padding-top: 5rem;
    margin-bottom: 2.7rem;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    -webkit-box-reflect: below -0.2em -webkit-linear-gradient(top, transparent, transparent 50%, rgba(0, 0, 0, 0.1))
  }
  
  .title2 {
    padding-top: 3.5rem;
    margin-bottom: 2.7rem;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    -webkit-box-reflect: below -0.2em -webkit-linear-gradient(top, transparent, transparent 50%, rgba(0, 0, 0, 0.1))
  }
  
  @media screen and (max-width:600px) {
   .container-2-content {
    width: 90%;
    margin: 0 auto;
  }
  
   .container-2-content-div {
    height: 300px;
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: hidden;
   }
    .container-2-content-div2-bg {
      color: #fff;
      font-size: 25px
    }

    .container-2-content-div2-sm {
      color: #fff;
      font-size: 10px
    }
    
   .container-2-content-div2 {
    position: absolute;
    text-align: center;
    top: 80px;
   }
   
   .container-3-content {
    width: 90%;
    margin: 0 auto;
    }
    .container-3-content-div {
    height: 200px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
   .container-3-content-desc {
    position: absolute;
    bottom: 35%;
    color: #fff
  }
    .title1 {
      padding-top: 4rem;
      margin-bottom: 2.7rem;
      text-align: center;
      font-size: 35px;
      font-weight: bold;
      -webkit-box-reflect: below -0.2em -webkit-linear-gradient(top, transparent, transparent 50%, rgba(0, 0, 0, 0.1))
    }
    .title2 {
      padding-top: 4rem;
      margin-bottom: 2.7rem;
      text-align: center;
      font-size: 35px;
      font-weight: bold;
      -webkit-box-reflect: below -0.2em -webkit-linear-gradient(top, transparent, transparent 50%, rgba(0, 0, 0, 0.1))
    }
  }
`;

export default IntroducePanel;