import React from "react";
import styled from "@emotion/styled";
import {PhoneOutlined, ScanOutlined, VerticalAlignTopOutlined} from "@ant-design/icons";
import $ from "jquery";
import officialAccountCode from "./pic/official-account-qr.jpg";
import bdContactCode from "./pic/bd-contact-qr.jpg";

const BasicSidedBar = ({className}) => {
    return (
        <div className={className}>
            <div className="side-bar-container">
                <div className="outer">
                    <PhoneOutlined className={"inner-phone-svg"} />
                    <div className="inner-phone">
                        17766420358
                    </div>
                </div>
                <div className="outer">
                    <ScanOutlined className={"inner-code-svg"}/>
                    <div className="inner-code">
                        <img src={officialAccountCode} alt={"qrcode"} style={{width: "100%", height: "100%"}}/>
                        <img src={bdContactCode} alt={"qrcode"} style={{width: "100%", height: "100%"}}/>
                    </div>
                </div>
                <div className="outer" onClick={() => {
                    $("html,body").animate({
                        "scrollTop": 0
                    }, 500);
                }}>
                    <VerticalAlignTopOutlined className={"inner-top"}/>
                </div>
            </div>
        </div>
    );
}

const SideBar = styled(BasicSidedBar)`
    .side-bar-container {
        position: fixed;
        right: 5px;
        top: 61vh; 
        width: 50px;
        z-index: 500;
    }
    
    .outer {
        width: 100%;
        height: 50px;
        margin-bottom: 5px; 
        display: flex;
        justify-content: center;
        align-items: center;  
        background-color: #f2f2f2; 
        cursor: pointer;
        position: relative;
    }
    
    .inner-phone {
        color: #e93f4aff;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        width: 0;
        position: absolute;
        top: 0;
        right: 50px;
        font-weight: bold;
        background-color: #f2f2f2;
        transition: width 300ms;
        overflow:hidden;
    }
    
    .inner-phone-svg {
        transform: rotate(90deg);
        font-size: 25px;
        color: #e93f4aff;
        transition: font-size 300ms;
    }
    
    .inner-code {
        height: 0;
        width: 0;
        position: absolute;
        top: 0;
        right: 50px;
        transition: all 300ms;
    }
    
    .inner-code-svg {
        font-size: 25px;
        color: #e93f4aff;
        transition: font-size 300ms;    
    }
    
    .inner-top {
        font-size: 25px;
        color: #e93f4aff;
        transition: font-size 300ms;  
    }
    
    .outer:hover .inner-phone {
        width: 140px;
        padding-left: 20px;
        transition: width 800ms;
    }
    
     .outer:hover .inner-phone-svg {
        font-size: 28px;
        transition: font-size 300ms;
    }
     .outer:hover .inner-code-svg {
        font-size: 28px;
        transition: font-size 300ms;
    }
    .outer:hover .inner-code {
        width: 100px;
        height: 100px;
        transition: all 800ms;
    }
    .outer:hover .inner-top {
        font-size: 28px;
        transition: font-size 300ms;
    }
`;

export default SideBar;