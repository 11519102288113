import React from "react";
import Astronaut from "./img/astronaut.svg";
import Cloud from "./img/cloud.svg";
import Planet from "./img/planet.svg";
import Indicator from "./img/404.svg";
import FloatingMatter from "./img/floating-matter.svg";
import Rocket from "./img/rocket.svg";
import Q1 from "./img/q1.svg";
import Q2 from "./img/q2.svg";
import Q3 from "./img/q3.svg";
import { Helmet } from "react-helmet-async";
import { Button } from "antd";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";

export const IndexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NotFoundPage = () => {
  let history = useHistory();

  const backToHome = () => {
    history.push("/");
  };

  return (
    <Container>
      <Helmet>
        <title>{"页面不存在"}</title>
      </Helmet>
      <div className={"planet"}>
        <img src={Planet} alt={"星球"} className={"objectPlanet"} />
      </div>
      <div className={"cloud"}>
        <img src={Cloud} alt={"云"} className={"objectCloud"} />
        <IndexContainer>
          <Button type="primary" onClick={backToHome}>
            返回首页
          </Button>
        </IndexContainer>
      </div>
      <div className={"indicator"}>
        <img src={Indicator} alt={"404"} className={"objectIndicator"} />
      </div>
      <div className={"floatingMatter"}>
        <img src={FloatingMatter} alt={"背景"} className={"objectFloating"} />
      </div>
      <div className={"rocket"}>
        <img src={Rocket} alt={"火箭"} className={"objectRocket"} />
      </div>
      <div className={"astronaut"}>
        <img className={"objectAstronaut"} src={Astronaut} alt={"宇航员"} />
      </div>
      <div className={"q1"}>
        <img className={"objectQ1"} src={Q1} alt={"问号1"} />
      </div>
      <div className={"q2"}>
        <img className={"objectQ2"} src={Q2} alt={"问号2"} />
      </div>
      <div className={"q3"}>
        <img className={"objectQ3"} src={Q3} alt={"问号3"} />
      </div>
    </Container>
  );
};

export default NotFoundPage;

const Container = styled.div`
  .cloud {
    top: 55%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
  }
  
  .planet{
    top: 25%;
    position: absolute;
    left: 50%;
    z-index: 30;
    transform: translateX(-50%);
  }
  
  .indicator{
    top: 35%;
    position: absolute;
    left: 46%;
    z-index: 20;
    transform: translateX(-46%);
  }
  
  .rocket{
    top: 50%;
    position: absolute;
    left: 43%;
    z-index: 20;
    transform: translateX(-43%);
  }
  
  @keyframes move-indicator {
  0%{
    transform: translate(0vh,0vh);
  }
  25%{
    transform: translate(-2vh,-22vh);
  }
  100%{
    transform: translate(-2vh,-22vh);
  }
  }
  
  @keyframes move-astronaut{
  0%{
    transform: translate(0vh,0vh);
  }
  20%{
    transform: translate(0vh,0vh);
  }
  35%{
    transform: translate(2vh,-14vh);
  }
  100%{
    transform: translate(2vh,-14vh);
  }
  }
    
  @keyframes move-planet{
  0%{
    transform: translate(0vh,0vh);
  }
  100%{
    transform: translate(0vh,-2vh);
  }
  }
    
  @keyframes move-floating{
  0%{
    opacity: 0.5;
    transform: translate(0vh,0vh);
  }
  100%{
    transform: translate(0vh,1vh);
  }
  }
    
  @keyframes move-q{
  0%{
    transform: translate(0vh,0vh);
    opacity: 1;
  }
  100%{
    transform: translate(0vh;0vh);
    opacity: 1;
  }
  }
    
  @keyframes move-rocket{
  100%{
    transform: translate(-50vh,-50vh);
  }
  }
    
  .astronaut{
    top: 34.5%;
    position: absolute;
    left: 53%;
    z-index: 10;
    transform: translateX(-50%);
  }
    
  .q1{
    top: 16.5%;
    position: absolute;
    left: 54.5%;
    z-index: 10;
    transform: translateX(-54%);
  }
    
  .q2{
    top: 17.5%;
    position: absolute;
    left: 56%;
    z-index: 10;
    transform: translateX(-53%);
  }
    
  .q3{
    top: 20.5%;
    position: absolute;
    left: 57%;
    z-index: 10;
    transform: translateX(-56%);
  }
    
  .objectAstronaut{
    width: 8vh;
    animation: move-astronaut ease-in-out 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    transform-origin: 50% 50%;
  }
  .objectIndicator{
    width: 13vh;
    animation: move-indicator ease-in-out 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    transform-origin: 50% 50%;
  }
  .floatingMatter{
    top: 12%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
    
  .objectPlanet{
    width: 25vw;
    animation: move-planet ease-in-out 2s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-direction: alternate;
    transform-origin: 50% 50%;
  
  .objectCloud{
    width: 65vw;
    overflow-x: hidden;
  };
  .objectRocket{
    width: 4vh;
    animation: move-rocket linear 2s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
  };
  .objectFloating{
    width: 60vw;
    overflow-x: hidden;
    animation: move-floating ease-in-out 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-direction: alternate;
    transform-origin: 50% 50%;
  };
  .objectLine{
    width: 5vw;
    overflow-x: hidden;
  };
  .objectQ1{
    opacity: 0;
    width: 1vw;
    overflow-x: hidden;
    animation: move-q ease-in-out 2s;
    animation-iteration-count: 1;
    animation-delay: 3s;
    animation-fill-mode: forwards;
    transform-origin: 50% 50%;
  };
  .objectQ2{
    opacity: 0;
    width: 1.5vw;
    overflow-x: hidden;
    animation: move-q ease-in-out 2s;
    animation-iteration-count: 1;
    animation-delay: 3s;
    animation-fill-mode: forwards;
    transform-origin: 50% 50%;
  };
  .objectQ3{
    opacity: 0;
    width: 1vw;
    overflow-x: hidden;
    animation: move-q ease-in-out 2s;
    animation-iteration-count: 1;
    animation-delay: 3s;
    animation-fill-mode: forwards;
    transform-origin: 50% 50%;
  }
`;
