import React from "react";
import styled from "@emotion/styled";
import {ReactComponent as Logo} from "./pic/logo.svg";
import {ReactComponent as Logo2} from "./pic/logo2.svg";
import {Link} from "react-router-dom";

const BasicHeader = ({className}) => {
    return (
        <div className={className}>
            <div className="header">
                <div className='header-content'>
                    <div className="header-logo">
                        <Logo className="logo1"/>
                        <Logo2 className="logo2"/>
                    </div>
                    <div className="header-nav">
                        <div>
                            <Link to="/about"
                                  style={{textDecoration: 'none', color: '#000', fontSize: '1rem'}}>关于我们</Link>
                        </div>
                        <div>
                            <Link to="/" style={{textDecoration: 'none', color: '#000', fontSize: '1rem', paddingRight: 10}}>首页</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const Header = styled(BasicHeader)`
  .header {
    width: 100%;
    height: 8vh;
    position: fixed;
    background-color: #fff;
    z-index: 200;
   }

  .header-content {
    display: flex;
    width: 1400px;
    height: 8vh;
    margin: 0 auto;
  }

  .header-logo {
    height: 100%;
    flex: 3;
  }
  
  .logo1 {
    width: 10%;
    height: 100%;
  } 
  
  .logo2 {
    width: 15%;
    height: 100%;
  }
   
  .header-nav {
    height: 100%;
    flex: 3;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .header-nav > span {
    padding-left: 3em;
    font-size: 1.2rem;
  }
  
  @media screen and (max-width:600px) {
    .header-content {
      display: flex;
      width: 90%;
      height: 8vh;
      margin: 0 auto;
   }
    .logo1 {
     width: 35%;
     height: 100%;
     margin-left: 0;
    }
    .logo2 {
     width: 45%;
     height: 100%
    }    
  }        
`;

export default Header;