import React from "react";
import styled from "@emotion/styled";
import {Col, Row} from "antd";
import {EnvironmentOutlined, MailOutlined, PhoneOutlined} from "@ant-design/icons";
const BasicFooter = ({className}) => {
    return (
        <div className={className}>
            <div className="container-4">
                <div className="container-4-placeholder"/>
                <div className="container-4-content-box">
                    <Row className="container-4-content">
                        <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                            <div>
                                <h2>关于我们</h2>
                                <p style={{cursor: 'pointer'}} onClick={() => {window.location.href = '/about?key=1'}}>企业简介</p>
                                <p style={{cursor: 'pointer'}} onClick={() => {window.location.href = '/about?key=2'}}>创意宣言</p>
                            </div>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                            <div>
                                <h2>会议策划</h2>
                                <p>行业学术峰会</p>
                                <p>企业峰会论坛</p>
                                <p>城市推介会</p>
                                <p>政府项目会议</p>
                                <p>赛事活动执行</p>
                                <p>海外商务会议</p>
                            </div>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                            <div>
                                <h2>展陈设计</h2>
                                <p>展台设计</p>
                                <p>展览搭建</p>
                                <p>主题特色展厅</p>
                                <p>城市规划馆</p>
                                <p>科技展览</p>
                                <p>博览会</p>
                                <p>VR展</p>
                            </div>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                            <div>
                                <h2>影视制作</h2>
                                <p>产品宣传TVC</p>
                                <p>纪录片拍摄</p>
                                <p>城市宣传片</p>
                                <p>企业数字宣传片</p>
                                <p>新媒体视频</p>
                                <p>会议活动主题视频</p>
                            </div>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                            <div>
                                <h2>品牌推广</h2>
                                <p>品牌创意广告</p>
                                <p>数字营销全案</p>
                                <p>VI视觉</p>
                                <p>IP文创</p>
                                <p>新媒体代运营</p>
                            </div>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                            <div>
                                <h2>联系我们</h2>
                                <p>
                                    <PhoneOutlined style={{transform: "rotate(90deg)"}}/>&nbsp;&nbsp;17766420358
                                </p>
                                <p>
                                    <MailOutlined />&nbsp;&nbsp;biz@ruiji-media.com
                                </p>
                                <div style={{display: 'flex'}}>
                                    <div><EnvironmentOutlined />&nbsp;&nbsp;</div>
                                    <div>
                                        <p style={{marginTop: 0}}>上海</p>
                                        <p>南京</p>
                                        <p>杭州</p>
                                        <p>湖州</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

const MyFooter = styled(BasicFooter)`
 .container-4 {
    width: 100%;
    background-color: rgb(51, 51, 51);
    color: #aaa;
  }
  
  .container-4-placeholder {
    width: 100%;
    height: 4rem;
  }
  .container-4-content-box {
    width: 1400px;
    margin: 0 auto;
    padding-bottom: 1rem;
    border-bottom: thin solid #aaa;
  }
  
  .container-4-content {
    width: 100%;
    margin: 0 auto;
  }
  
  @media screen and (max-width:600px) {
    .container-4-content-box {
    width: 90%;
    margin: 0 auto;
    }    
  }
`;

export default MyFooter;