import React from "react";
import styled from "@emotion/styled";
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";

import banner1 from "./pic/banner1.jpg";
import banner2 from "./pic/banner2.jpg";
import banner3 from "./pic/banner3.jpg";

// Import Swiper styles
import "swiper/swiper.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

// import required modules
import {Autoplay, Pagination} from "swiper";

const BasicSwiper = ({className}) => {
    return (
        <div className={className}>
            <div className="container-1">
                <Swiper
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="container-1-swiper"
                >
                    <SwiperSlide>
                        <div className="container-1-swiper-img" style={{backgroundImage: `url('${banner1}')`, backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat'}}>
                            <div className="container-1-swiper-img-div">
                                <div className="container-1-swiper-img-div-1">
                                    <div>瑞意进取</div>
                                    <div className="container-1-swiper-img-div-1-1">吉致服务</div>
                                </div>
                                <div className="container-1-swiper-img-div-11">
                                    <div className="container-1-swiper-img-div-111">用精彩创意和精致服务</div>
                                    <div className="container-1-swiper-img-div-112">助力客户构建品牌、传播价值、成就不凡</div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container-1-swiper-img" style={{backgroundImage: `url('${banner2}')`, backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat'}}>
                            <div className="container-1-swiper-img-div">
                                <div className="container-1-swiper-img-div-2">
                                    <div>创意宣言</div>
                                </div>
                                <div className="container-1-swiper-img-div-11">
                                    <div className="container-1-swiper-img-div-111">我们是一群对创意和美狂热追求的人</div>
                                    <div className="container-1-swiper-img-div-112">专注、高效、速度、责任感、对细节的极致追求</div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container-1-swiper-img" style={{backgroundImage: `url('${banner3}')`, backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat'}}>
                            <div className="container-1-swiper-img-div">
                                <div className="container-1-swiper-img-div-2">
                                    <div>元宇宙会展</div>
                                </div>
                                <div className="container-1-swiper-img-div-12">
                                    <div className="container-1-swiper-img-div-111">用最新技术</div>
                                    <div className="container-1-swiper-img-div-112">打造新一代沉浸式活动会议展览平台</div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
}

const MySwiper = styled(BasicSwiper)`
      .container-1 {
    width: 100%;
    padding-top: 8vh;
  }
  
  .container-1-swiper {
    width: 100%;
    display: flex;
  } 
  
  .container-1-swiper-img {
    width: 100vw;
    height: 550px;
    //text-shadow: 0px 3px #000
  }
  
  .container-1-swiper-img-div {
    margin: 0 auto;
    width: 1400px;
    display: flex;
    font-family: iconfont-1;
    color: #fff;
  }
  
  .container-1-swiper-img-div-1 {
    padding-top: 100px;
    font-size: 80px;
    flex: 1;
  }

  .container-1-swiper-img-div-1-1 {
    margin-left: 50px;
  }
  .container-1-swiper-img-div-2 {
    padding-top: 140px;
    font-size: 80px;
    flex: 1
  }
  
  .container-1-swiper-img-div-11 {
    flex: 1;
    padding-top: 350px;
    font-size: 30px;
    position: relative  
  }
  
  .container-1-swiper-img-div-111 {
    position: absolute;
    right: 0  
  }

  .container-1-swiper-img-div-112 {
    position: absolute;
    right: 0;
    margin-top: 42px
  }
  .container-1-swiper-img-div-12 {
    flex: 1;
    padding-top: 370px;
    font-size: 30px;
    position: relative
  }
  
   @media screen and (max-width:600px) {
   .container-1 {
    width: 100%;
    padding-top: 8vh;
    }
   .container-1-swiper-img {
    width: 100vw;
    height: 30vh;
     //text-shadow: 0px 1px #000
    }
    .container-1-swiper-img-div {
      margin: 0 auto;
      width: 100vw;
      display: flex;
      color: #fff;
    }
    .container-1-swiper-img-div-1 {
      padding-top: 40px;
      font-size: 20px;
      flex: 1
    }
    .container-1-swiper-img-div-1-1 {
      margin-left: 20px;
    }
    .container-1-swiper-img-div-2 {
      padding-top: 60px;
      font-size: 20px;
      flex: 1
    }
    .container-1-swiper-img-div-11 {
      flex: 2;
      padding-top: 150px;
      font-size: 12px;
      position: relative;
      margin-right: 10px;
    }
    .container-1-swiper-img-div-112 {
      position: absolute;
      right: 0;
      margin-top: 20px
    }
    .container-1-swiper-img-div-12 {
      flex: 2;
      padding-top: 150px;
      font-size: 12px;
      position: relative;
      margin-right: 10px;
    }
    .container-1-swiper {
    width: 100%;
    display: flex;
    text-align: center;
    }
   }
`;

export default MySwiper;